import React, { useState , useRef } from 'react';
import { Redirect } from 'react-router-dom';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import ListaTiposDenuncia from '../components/ListaTiposDenuncia'
import ConfigurationService from "../services/ConfigurationService";
import Captcha from '../components/Captcha'
import DenunciaService from "../services/DenunciaService";
import ValidationService from "../services/ValidationService";

const config = new ConfigurationService();

const captchaCallback = (response) => {
    //console.log('Done!!!! 3');
};

function DenunciasMarcoEtico() {
    const rut = useRef();
    const nombres = useRef();
    const email = useRef();
    const tipoDenuncia = useRef();
    const descripcion = useRef();
    const archivo = useRef();

    const [submitForm, setSubmitForm] = useState(false);
    const [showText, setShowText] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(window.grecaptcha){
            var response = window.grecaptcha.getResponse();
            
            if(response.length){

                var formData = new FormData(); 
                formData.append('email',email.current.value);
                formData.append('rut',rut.current.value);
                formData.append('nombres',nombres.current.value);
                formData.append('idCanal',config.CANAL_MARCO_ETICO_CODE);
                formData.append('idTipo',tipoDenuncia.current.value);
                formData.append('descripcion',descripcion.current.value);
                formData.append('anonimo',false);
                formData.append('archivo',archivo.current.files[0]);
                formData.append('token',response);

                var valido = ValidationService.validarFormulario(formData);
                console.log(valido[0]);
                if(valido[0]){
                    var resultado = await DenunciaService.registrarDenuncia(formData);

                    if(resultado.code !== undefined && resultado.code === '00'){
                        setSubmitForm(true);
                    }else{
                        setShowText('Error al registrar denuncia.');
                        setSubmitForm(false);
                    }
                }else{
                    setShowText(valido[1]);
                    setSubmitForm(false);
                }
            }else{
                setShowText('Seleccione el captcha.');
            }
        }else{
            setShowText(valido[1]);
            setSubmitForm(false);
        }
    }
    
    if (submitForm) {
        return <Redirect to='/denunciarecepcionada' />
    }
    return (
        <>
            <Navbar></Navbar>
                <div className = "ca-form">
                    <div className="container py-5">
                    <div className="row col-md-10 mx-auto">
                    <h2 className="pb-5">Canal de Denuncia por Marco Ético y de Conducta</h2>
                    <form onSubmit={handleSubmit}  encType="multipart/form-data">
                        <div className="row col-lg-8 offset-lg-2 py-5">
                            <div className="col-md-6 ps-sm-0 ps-md-5">
                                <label htmlFor="inputRut" className="form-label">RUT</label>
                                <input ref={rut} id="inputRut" type="text" className="form-control" placeholder="12345678-9" aria-label="Rut" required></input>

                                <label htmlFor="inputName" className="form-label">Nombre</label>
                                <input ref={nombres} id="inputName" type="text" className="form-control" placeholder="Nombre Apellido" aria-label="Nombre" required></input>

                                <label htmlFor="inputEmail" className="form-label">E-MAIL</label>
                                <input ref={email} id="inputEmail" type="email" className="form-control" placeholder="nombre@servidor.com" aria-label="Email" required></input>

                                <label htmlFor="selectComplaint" className="form-label">Denuncia</label>
                                <ListaTiposDenuncia idCanal={config.CANAL_MARCO_ETICO_CODE} forwardRef={tipoDenuncia}></ListaTiposDenuncia>

                            </div>
                            <div className="col-md-6 ps-sm-0 ps-md-5">
                                <label htmlFor="inputDescription" className="form-label">Descripción</label>
                                <textarea ref={descripcion} className="form-control" id="inputDescription" placeholder="Descripción de la denuncia, máximo 500 caracteres." required></textarea>

                                <label htmlFor="inputFile" className="form-label">Adjuntar Archivo (Max. 10 MB)</label>
                                <input ref={archivo} className="form-control" type="file" id="inputFile" accept={config.FILE_EXTENSIONS}></input>

                               
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center my-5">
                            <div className="col-md-3 text-center">
                                <Captcha callback={captchaCallback}></Captcha>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-4 text-center">
                                
                                { showText ? 
                                <div className="alert alert-danger ca-alert" role="alert">
                                    <p>{showText}</p>
                                </div> : null 
                                }

                                <button type="submit" className="btn ca-button ca-button--secondary">Enviar Denuncia</button>
                            </div>
                        </div>
                    </form>
                    </div>
                    </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default DenunciasMarcoEtico