import React, { useEffect, useState ,forwardRef} from "react";
import ConfigurationService from '../services//ConfigurationService';


const config = new ConfigurationService();
const ListaTiposDenuncia = forwardRef((prop, ref) => {
    const { idCanal ,forwardRef,onChangeEvent } = prop;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
  
    const customChangeEvent = (e) => {
      console.log('change interno');
      console.log(e.target.value);
      //setState(e.target.value);
      if(onChangeEvent){
       onChangeEvent(e);
      }
   };

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
      fetch(
            config.URL_REST_CLIENT_PATH+ "/tipo/lista/"+idCanal,
            { 
            method: 'get', 
            headers: new Headers({
                'Authorization': config.AUTH_HEADER, 
                'Content-Type': 'application/x-www-form-urlencoded'
            })
            }
        )
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
          },
          // Nota: es importante manejar errores aquí y no en 
          // un bloque catch() para que no interceptemos errores
          // de errores reales en los componentes.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [idCanal])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <select 
        ref={forwardRef} 
        id={(forwardRef? 'selectComplaint' : '')}
        className={(forwardRef? 'form-select form-control' : '')}
        aria-label="Select Complaint" 

          onChange={(e) => {
            customChangeEvent(e);
            }}
          required>

            <option value="">Seleccionar</option>
          {items.map((item ,i )=> (
            <option key={i}  value={item.idTipo}>
               {item.nombre}
            </option>
          ))}
        </select>
      );
    }
  });

export default ListaTiposDenuncia
