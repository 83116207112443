import React, {Fragment, useState, useEffect} from 'react';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import {useAuth} from '../services/AlemanaAuth';

import {
    useHistory,
    useLocation
  } from "react-router-dom";

import DenunciaService from "../services/DenunciaService";

function Login() {
    const [data, setData] = useState({
        username: '',
        password: ''
    })

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name] : event.target.value
        })
    }

    const sendData = (event) => {
        event.preventDefault()
        console.log('Sending data...')
        
        login(data); 
    }

    /* login */
    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();

    let { from } = location.state || { from: { pathname: "/denuncias" } };
    let login = () => {
        var props = {
            data: data,
            cb : () => {
                history.replace(from);
            }
        }

        auth.signin(props);
        
    };

    useEffect(() => {
        var token = window.sessionStorage.getItem('tk');
        var username = window.sessionStorage.getItem('cn');
        if (token) {
            var props = {
                data : {
                    username : username,
                    token : token
                },
                exito : () => {
                    history.replace(from);
                },
                error : () =>{
                    history.replace({ pathname: "/login" } );
                }
            }
            auth.revalidate(props);
        }
    },[])

    return (
        <>
        <Navbar></Navbar>
            <div className = "ca-login ca-form py-5">

                <div className = "container">
                <div className="row col-md-10 mx-auto">
                <h2 className="py-5">Inicie Sesión para consultar sobre denuncias realizadas</h2>
                <form onSubmit={sendData}>
                    <div className="row justify-content-center d-flex">
                        <div className="col-md-6">
                            <label htmlFor="inputUser" className="form-label">Usuario</label>
                            <input onChange={handleInputChange}  name="username" id="inputUser" type="mail" className="form-control" placeholder="usuario@dominio.com" aria-label="Usuario"></input>

                            <label htmlFor="inputPassword" className="form-label">Contraseña</label>
                            <input onChange={handleInputChange}  name="password" id="inputPassword" type="password" className="form-control" aria-label="Contraseña"></input>

                            <button type="submit" className="btn ca-button ca-button--secondary">Iniciar Sesión</button>
                        </div>
                    </div>
                </form>
                </div>
                </div>
            </div>
        <Footer></Footer>
        </>
    )
}

export default Login