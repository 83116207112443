import React, { useEffect } from "react";
import { useTable, useFilters, useGlobalFilter, usePagination } from "react-table";

import ConfigurationService from '../services//ConfigurationService';


const config = new ConfigurationService();

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return null;
}

function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    console.log(rows, id, filterValues)
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        console.log(time, ed, sd)
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

dateBetweenFilterFn.autoRemove = val => !val;

// Our table component
function Table({
   columns, 
   data ,
   fetchData,
   loading,
   idCanal,
   idTipo,
   isAnonimo,
   fechaInicio,
   fechaFin,
   pageCount: controlledPageCount
   }) {
    
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      dateBetween: dateBetweenFilterFn,   /*<- LIKE THIS*/
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex , pageSize},
  } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { 
          pageIndex: 0, 
          pageSize: config.PAGE_SIZE,
          hiddenColumns : ["uuid"],
          idCanal: '',
          idTipo: '',
          isAnonimo:'',
          fechaInicio:'',
          fechaFin:''
        },
        manualPagination: true, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount,
        autoResetPage: false,
      },
      useGlobalFilter, // useGlobalFilter!
      useFilters, // useFilters!
      usePagination,
    );

    function changeColSpan () {
      let table = document.getElementById ("reactTable");

      // The first cell of the first row in the table
      let colToSpan = table.rows[0].cells[1];
      colToSpan.colSpan = "2";
    }

    useEffect(() => {
      console.log('useEFFECT: TABLE');
  
      let table = document.getElementById("reactTable");
      if (table){
        changeColSpan();
      }
     
      fetchData({ pageIndex, pageSize ,idCanal,idTipo,isAnonimo,fechaInicio,fechaFin});
    }, [ pageIndex, pageSize,idCanal,idTipo,isAnonimo,fechaInicio,fechaFin]);
  
  return (
    <div className="table-responsive">
      <div className="ca-table">
      <div className="container">
      <table {...getTableProps()} id="reactTable">
        <thead className="ca-table-thead-react">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead> 
        <thead className="ca-table-thead-manual">
            <tr role="row">
                <th colSpan="1" role="columnheader"><div className="ca-table-title">N° de denuncia</div></th>
                <th colSpan="1" role="columnheader"><div className="ca-table-title">Fecha de Ingreso</div></th>
                <th colSpan="1" role="columnheader"><div className="ca-table-title">Nombre denunciante</div></th>
                <th colSpan="1" role="columnheader"><div className="ca-table-title">Canal denuncia</div></th>
            </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="ca-pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
      
        
      </div>


      </div>
    </div>
    </div>
  );
}

export default Table;