import React, { useContext, createContext, useState ,useEffect} from "react";
import DenunciaService from "../services/DenunciaService";

    /** For more details on
     * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
     * refer to: https://usehooks.com/useAuth/
     */
    const authContext = createContext();
    
    export function ProvideAuth({ children }) {
        const auth = useProvideAuth();
        return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
        );
    }
    
    export const useAuth = () => {
        return useContext(authContext);
    };
  
    function useProvideAuth() {
        const [user, setUser] = useState(null);
        
        const signin = async props => {

            var resp = await DenunciaService.login(props.data);
            
            if(resp.code != undefined && resp.code == '-1'){
                console.log('Credenciales invalidas');
                alert('Usuario / Clave invalidos.');
            }else{
                setUser(props.data.username);
                DenunciaService.registerSession(props.data.username,resp.token);
                props.cb();
            }
        };

        const revalidate = async props => {

            var resp = await DenunciaService.revalidate(props.data);
            
            if(resp){
                setUser(props.data.username);
                props.exito();
            }else{
                props.error();
                DenunciaService.removeSession();
            }
        };
    
        const signout = cb => {
            
                setUser(null);
                sessionStorage.removeItem('tk');
                sessionStorage.removeItem('cn');
                cb();
            
        };
    
        return {
        user,
        signin,
        revalidate,
        signout
        };
    }
  