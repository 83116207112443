class ConfigurationService {
 
    //URL_REST_CLIENT_PATH = "http://54.85.117.232/formulario-denuncias-services";

	URL_REST_CLIENT_PATH = process.env.REACT_APP_URL_SERVICES;
    AUTH_USERNAME = "admin";
    AUTH_PASSWORD= "akMcZm5GaQ";
    AUTH_HEADER = 'Basic '+btoa(this.AUTH_USERNAME+':'+this.AUTH_PASSWORD);
    CANAL_LEY_20393_CODE = 1;
    CANAL_LEY_20393_DESCRIPTION = "Ley 20.393";
    CANAL_MARCO_ETICO_CODE = 2;
    CANAL_MARCO_ETICO_DESCRIPTION = "Marco ético y de conducta";
    CAPTCHA_PUBLIC_KEY = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY;
    FILE_EXTENSIONS = ".jpg,.jpeg,.pdf";
    PAGE_SIZE = 10;
}
export default ConfigurationService;