import React, { useEffect, useState ,forwardRef} from "react";
import ConfigurationService from '../services//ConfigurationService';


const config = new ConfigurationService();
const ListaCanales = forwardRef((prop, ref) => {
    const { onChangeEvent  } = prop;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    //const [state, setState] = useState('');
  
    const customChangeEvent = (e) => {
       console.log('change interno');
       console.log(e.target.value);
       //setState(e.target.value);
       if(onChangeEvent){
        onChangeEvent(e);
       }
    };
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
      fetch(
            config.URL_REST_CLIENT_PATH+ "/canal/lista",
            { 
            method: 'get', 
            headers: new Headers({
                'Authorization': config.AUTH_HEADER, 
                'Content-Type': 'application/x-www-form-urlencoded'
            })
            }
        )
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
          },
          // Nota: es importante manejar errores aquí y no en 
          // un bloque catch() para que no interceptemos errores
          // de errores reales en los componentes.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <select /*value={state}*/ onChange={(e) => {
          customChangeEvent(e);
          }}>
            <option value="">Seleccionar</option>
          {items.map((item, i)  => (
            <option key={i} value={item.idCanal}>
               {item.nombre}
            </option>
          ))}
        </select>
      );
    }
  });


export default ListaCanales
