import ConfigurationService from './ConfigurationService';
const { validarRUT } = require('validar-rut')

const config = new ConfigurationService();

const validarFormulario = (formData) => {
    var email = formData.get('email');
    var rut = formData.get('rut');
    var nombres = formData.get('nombres');
    var idCanal = formData.get('idCanal');
    var idTipo = formData.get('idTipo');
    var descripcion = formData.get('descripcion');
    var anonimo = formData.get('anonimo');
    var archivo = formData.get('archivo');
    var token = formData.get('token');
    
    if(token === undefined || token === ''){
        return [false, 'Seleccione el captcha.'];
    }

    if(idCanal === undefined || idCanal===''){
        return [false, 'Ingrese un Canal válido.'];
    }
    
    if(idTipo === undefined || idTipo===''){
        return [false, 'Ingrese un idTipo válido.'];
    }
    
    else if(descripcion === undefined || descripcion===''){
        return [false, 'Ingrese una descripción válida.'];
    }

    if(anonimo === undefined || anonimo===''){
        return [false,'Ingrese un indicador de anonimo válido.'];
    }
    
    if(archivo === undefined || archivo===''){
        return [false, 'Ingrese un archivo válido.'];
    }
    
    if(anonimo === 'false'){
        if(email === undefined || email===''){
            return [false, 'Ingrese un email válido.'];
        }
        
        if(rut === undefined || rut==='' || !validarRUT(rut) ){
            return [false, "Ingrese un rut valido"];
        }
        
        if(nombres === undefined || nombres===''){
            return [false, 'Ingrese nombres válido.'];
        }
    }

    return [true, "Formulario correcto"];

};

// const validaRut = (rutCompleto) => {
//     if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
//         return false;
//     var tmp 	= rutCompleto.split('-');
//     var digv	= tmp[1]; 
//     var rut 	= tmp[0];
//     if ( digv === 'K' ) digv = 'k' ;
//     return (dv(rut) === digv );
// };

// const dv = (T) =>{
//     var M=0,S=1;
//     for(;T;T=Math.floor(T/10))
//         S=(S+T%10*(9-M++%6))%11;
//     return S?S-1:'k';
// };

const validaciones = {
    validarFormulario
};    
        
export default validaciones;