import ConfigurationService from './ConfigurationService';


const config = new ConfigurationService();

const listaCanales = async () => {
  return fetch(
        config.URL_REST_CLIENT_PATH+ "/canal/lista",
        { 
          method: 'get', 
          headers: new Headers({
            'Authorization': config.AUTH_HEADER, 
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const searchDenuncias = async (params) => {
  const { pageSize, pageIndex ,idCanal,idTipo,isAnonimo,fechaInicio,fechaFin} = params;
  console.log('Servicio:'+params);
  return fetch(
        config.URL_REST_CLIENT_PATH+ "/denuncia/search?page="+pageIndex
          +"&size="+pageSize+"&idCanal="+idCanal+'&idTipo='+idTipo+'&anonimo='+isAnonimo
          +"&fechaInicio="+fechaInicio+"&fechaFin="+fechaFin,
        { 
          method: 'get', 
          headers: new Headers({
            'Authorization': config.AUTH_HEADER, 
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const getDetalleDenuncia = async (uuid) => {
  return fetch(
        config.URL_REST_CLIENT_PATH+ "/denuncia/"+uuid,
        { 
          method: 'get', 
          headers: new Headers({
            'Authorization': config.AUTH_HEADER, 
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const getURLDocumento = (uuid) => {
  return config.URL_REST_CLIENT_PATH+ "/denuncia/"+uuid+"/download";
};

const registrarDenuncia = async (formData) => {
  return fetch(
        config.URL_REST_CLIENT_PATH+ "/denuncia/register",
        { 
          method: 'post', 
          headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            'Authorization': config.AUTH_HEADER, 
          }),
          body: formData,
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const login = async (props) => {
  var formData = new FormData(); 
  formData.append('username',props.username);
  formData.append('password',props.password);

  return fetch(
        config.URL_REST_CLIENT_PATH+ "/login",
        { 
          method: 'post', 
          headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            'Authorization': config.AUTH_HEADER
          }),
          body: formData
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const revalidate = async (props) => {
  var formData = new FormData(); 
  formData.append('usuario',props.username);
  formData.append('token',props.token);
  return fetch(
        config.URL_REST_CLIENT_PATH+ "/revalidate",
        { 
          method: 'post', 
          headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            'Authorization': config.AUTH_HEADER
          }),
          body: formData,
        }
      ).then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        return json;
      })
      .catch(error => {
        console.log(error);
        this.handleError(error);
      });
};

const registerSession = (username,token) => {
  sessionStorage.setItem('cn', username);
  sessionStorage.setItem('tk', token);
};

const removeSession = () => {
  sessionStorage.removeItem('cn');
  sessionStorage.removeItem('tk');
};

const servicios = {
  searchDenuncias,
  getDetalleDenuncia,
  getURLDocumento,
  registrarDenuncia,
  listaCanales,
  login,
  revalidate,
  registerSession,
  removeSession
};    
        
export default servicios;