import React from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";


// Home cards data

const list1 = [
  "Lavado de activos",
  "Financiamiento del terrorismo",
  "Receptación",
  "Cohecho a funcionarios públicos nacional o extranjero",
  "Administración desleal",
  "Apropiación indebida",
  "Corrupción entre particulares",
  "Negociación incompatible"
];

const list2 = [
  "Aceptación Regalos de Proveedores o quien pretende serlo",
  "Acoso Sexual",
  "Acoso Laboral",
  "Conflictos de Interés",
  "Otro",
];

function Content() {
  return (
    <div className="ca-content">
      <div className="container py-5">
        <div className="row col-md-10 mx-auto justify-content-center">
        <h2>Canal de denuncias Alemana Seguros</h2>
        <p className="py-5">
          El objetivo de este canal es permitir la denuncia de situaciones que
          podrían involucrar a Alemana Seguros con la comisión de los delitos de
          la Ley 20.393 y la Ley 21.121 sobre Responsabilidad Penal de las
          Personas Jurídicas o bien eventuales transgresiones a los principios y
          valores del Marco Ético y de Conducta de Alemana Seguros, dentro de la
          comunidad de colaboradores, proveedores y clientes.
        </p>
        <p>
          Este canal de denuncias forma parte de nuestro Modelo de Prevención de
          Delitos (MPD) de Alemana Seguros S.A.
        </p>
        <p>
          Se deja constancia que este servicio no es un canal de requerimientos
          o reclamos. Para ingresarlos favor dirigirse a
          seguros@alemanaseguros.cl
        </p>

        <div className="row py-5">
          <div className="col-md-6 mb-4">
            <div className="ca-card">
              <div className="container p-5">
                <h3>Canal de Denuncia por Ley 20.393</h3>
                <div className="ca-card__list">
                  <ul>
                    {list1.map(function (item, index) {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
                <button
                  className="btn ca-button"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Denunciar
                </button>
              </div>

              <Modal></Modal>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="ca-card">
              <div className="container p-5">
                  <h3 className="pb-2">Canal de Denuncia por Marco <br></br> Ético y de Conducta</h3>
                  <div className="ca-card__list">
                    <ul>
                      {list2.map(function (item, index) {
                        return <li key={index}>{item}</li>;
                      })}
                    </ul>
                  </div>
                  <Link to={'/denunciasmarcoetico'} className="nav-link btn ca-button">Denunciar</Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Content;