//import React from "react";
import { Link } from "react-router-dom";
import Table from "../components/Table";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import DenunciaService from "../services/DenunciaService";
import React, { useState ,useEffect } from "react";

import ListaTiposDenuncia from '../components/ListaTiposDenuncia'
import ListaCanales from '../components/ListaCanales'
import { configure } from "@testing-library/react";

import ConfigurationService from "../services/ConfigurationService";

const config = new ConfigurationService();


function DenunciaList() {
  // Get data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = useState(0);
  const [idCanal, setIdCanal] = useState('');
  const [idTipo, setIdTipo] = useState('');
  const [isAnonimo, setIsAnonimo] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const fetchIdRef = React.useRef(0)

  const fetchData = async ({ pageSize, pageIndex ,idCanal,idTipo,isAnonimo,fechaInicio,fechaFin }) => {
    const fetchId = ++fetchIdRef.current
    console.log('fetchData:');
    setLoading(true);
    

    if (fetchId === fetchIdRef.current) {
      const listaDenuncias =  await DenunciaService.searchDenuncias({ 
        pageSize, pageIndex ,idCanal, idTipo,isAnonimo,fechaInicio,fechaFin
      });
      setData(listaDenuncias.denuncias);
      setPageSize(Math.ceil(listaDenuncias.totalItems / pageSize));
      setPage(listaDenuncias.currentPage);
      setLoading(false);
    }else{
      console.log('nada ...');
    }

  };

   //Table filters
  function CanalColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    console.log(filterValue);
    console.log(id);
    // Render a multi-select box
    return (
      <ListaCanales  onChangeEvent={(e) => {
        console.log('change externo');
        console.log(e.target.value);
        setFilter(e.target.value || '');
        setIdCanal(e.target.value || '');
        setPage(0);
      }}></ListaCanales>
    );
  }

  function TipoDenunciaColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    console.log(filterValue);
    console.log(id);
    // Render a multi-select box
    return (
      <ListaTiposDenuncia idCanal={idCanal} onChangeEvent={(e) => {
        console.log('change externo');
        console.log(e.target.value);
        setFilter(e.target.value || '');
        setIdTipo(e.target.value || '');
        setPage(0);
      }}></ListaTiposDenuncia>
    );
  }

  function AnonimoColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {

    const Users=[
      {
        name:"Si",
        rollNo: true
      },
      {
        name:"No",
        rollNo: false
      }
    ];

    console.log(filterValue);
    console.log(id);
    // Render a multi-select box
    return (

      <select
        value={filterValue}
        onChange={(e) => {
          console.log('change externo');
          console.log(e.target.value);
          setFilter(e.target.value || '');
          setIsAnonimo(e.target.value);
          setPage(0);
        }}
      >
        <option value="">Seleccionar</option>
      
        {Users.map((option, i) => (
          <option key={i} value={option.rollNo}>
            {option.name}
          </option>
        ))}
 
      </select>
    );
  }

  function CustomDateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    
    return (
      <div
        style={{
          display: "flex"
        }}
      >
        <div className="ca-table-flex">
          (Desde)
          <input
            className="ca-table-date"
            value={filterValue[0]}
            type="date"
            //min={min.toISOString().slice(0, 8)}
            onChange={(e) => {
              const val = e.target.value;
              setFechaInicio(val);
              setFilter((old = []) => [val ? val : undefined, old[0]]);
            }}
            style={{
              width: "170px",
              marginRight: "0.5rem",
            }}
          />
        </div>
        <div className="ca-table-flex">
          (Hasta)
          <input
            className="ca-table-date"
            value={filterValue[1]}
            type="date"
            //max={max.toISOString()}
            onChange={(e) => {
              const val = e.target.value;
              setFechaFin(val);
              setFilter((old = []) => [old[0], val ? val : undefined]);
            }}
            style={{
              width: "170px",
              marginLeft: "0.5rem",
            }}
          />
        </div>
      </div>
    );
  }
  // Table Data

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Seleccionar</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "N° UUID",
        accessor: "uuid",
      },
      {
        Header: "N° de denuncia",
        accessor: "id",
      },
      {
        id : "fechaIngreso",
        Header: "Fecha de Ingreso",
        accessor: d => {
          return new Intl.DateTimeFormat('es-CL', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(d.fechaIngreso)
        },
        Filter : CustomDateRangeColumnFilter
      },
      {
        Header: "Nombre Denunciante",
        accessor: "nombres",
      },
      {
        Header: "Canal de Denuncia",
        //accessor: "idCanal",
        id: "idCanal",
        //Filter: SelectColumnFilter,
        Filter : CanalColumnFilter,
        //filter: "includes",
        accessor: cell => {
          var canalDesc = '';
          if(cell.idCanal === config.CANAL_LEY_20393_CODE){
             canalDesc = config.CANAL_LEY_20393_DESCRIPTION;
          }else if(cell.idCanal === config.CANAL_MARCO_ETICO_CODE){
            canalDesc = config.CANAL_MARCO_ETICO_DESCRIPTION;
          }

          return canalDesc;
        }
      },
      {
        Header: "Tipo Denuncia",
        accessor: "idTipo",
        //Filter: SelectColumnFilter,
        Filter: TipoDenunciaColumnFilter
        //filter: "includes",
      },
      {
        Header: "Anonima",
        accessor: "anonimo",
        Cell: (cell) => {
          return cell.anonimo ? "Sí" : "No";
        },
        //Filter: SelectColumnFilter,
        Filter:AnonimoColumnFilter,
        //filter: "includes",
      },
      {
        Header: "",
        id : "link",
        accessor: row => row.uuid,
        Cell: ({ cell }) => (
          <Link
            to={`/denuncia/${cell.row.values.uuid}`}
            className="btn ca-button ca-button--secondary"
          >
            Ver Detalle
          </Link>
        ),
      },
    ],
    []
  );



  return (
    <div>
      <Navbar></Navbar>

      <div className="container py-5">
        <div className="row col-md-10 mx-auto">
        <h2 className="py-5">Módulo de consultas de Denuncias</h2>
        <h3 className="py-5">Filtrar por:</h3>
      

      <Table 
        columns={columns} 
        data={data} 
        fetchData={fetchData} 
        loading={loading} 
        pageCount={pageSize}
        idCanal={idCanal}
        idTipo={idTipo}
        isAnonimo={isAnonimo}
        fechaInicio={fechaInicio}
        fechaFin={fechaFin}
        />
        </div>
        </div>
      <Footer></Footer>
    </div>
  );
}


export default DenunciaList;