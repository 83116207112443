import React, { useEffect, useState } from "react";
import ConfigurationService from '../services/ConfigurationService';


const config = new ConfigurationService();

const Captcha = params => {

  const [isLoaded, setLoad] = useState(false);

  useEffect(() => {
    loadScript();
  }, []);

  const loadScript = async () => {
    const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);
        setLoad(true);
      return () => {
          setLoad(false);
          document.body.removeChild(script);
        }
  };

    if(isLoaded){
      const { callback } = params;

      const captchaCallback = (response) => {
        console.log(window.grecaptcha);
        callback(response);
      };

      window.captchaCallback = captchaCallback;

      return (
          <div 
            className="g-recaptcha" 
            data-sitekey={config.CAPTCHA_PUBLIC_KEY}
            data-callback="captchaCallback"
          >
          </div>
      );

    }else {
      return (
            <div>Loading....</div>
        );
    }

    
    

    
  }

export default Captcha