import React from 'react'
import { Link ,useHistory} from 'react-router-dom'

import {useAuth} from '../services/AlemanaAuth';

function Navbar() {
	let history = useHistory();
    let auth = useAuth();

    return (
        <div>
            <header>
                <div className="wrap flex-stretch"> <div className="btn-menu order-3 order-lg-2 close">
                    <span></span>
                    <span></span>
                    <span></span>
                </div> <a href="https://www.alemanaseguros.cl" id="1" className="logo flex-center order-1 order-lg-1">
                        <img src="logo.svg" alt="" /> </a>
                    <nav className="order-2 order-lg-3">

                        <ul>
                            <li className="dropdown"><a href="/">Nuestros Seguros <em className="fas fa-chevron-down"></em></a>
                                <ul className="submenu">
                                    <li><span>SEGUROS DE SALUD</span></li>
                                    <li><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-salud">Seguro Alemana + Salud</a></li>
                                    <li><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-65">Seguro Alemana 65+</a></li>
                                    <li className="m-10"><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-sur">Seguro Alemana Sur</a></li>
                                    <li className="m-10"><a href="https://www.alemanaseguros.cl/alemana-accidentes/plan-full">Seguro Alemana Accidentes</a></li>
                                    <li><a href="https://www.alemanaseguros.cl/nuestros-seguros">Ver todos los seguros</a></li>
                                </ul>
                            </li>
                            <li><a href="https://www.alemanaseguros.cl/denuncia-de-siniestros">Denuncia de Siniestros</a></li>
                            <li><a href="https://www.alemanaseguros.cl/beneficios">Beneficios</a></li>
                            <li className="dropdown"><a href="#">Información Corporativa <em className="fas fa-chevron-down"></em></a>
                                <ul className="submenu">
                                    <li><a href="https://www.alemanaseguros.cl/mision">Misión y Visión</a></li>
                                    <li><a href="https://www.alemanaseguros.cl/directorio">Directorio y Administración</a></li>
                                    <li><a href="https://www.alemanaseguros.cl/antecedentes">Antecedentes de la Empresa</a></li>
                                    <li><a href="https://www.alemanaseguros.cl/redirect/terminos-y-condiciones-del-sitio-web">Términos y Condiciones de Uso de Sitio Web</a></li>
                                </ul>
                            </li>
                            
							{auth.user ? (
                              
                              <li> 
                                  <Link to="/" onClick={() => {auth.signout(() => history.push("/"));}} >
                                      Cerrar sesión</Link> 
                              </li>
                            ) : (
                               <li> </li>
                            )}
							
                        </ul>
                    

                    </nav> </div>
            </header>

            <div className="menu-mobile">
                <nav>
                <ul>
                    <li className="dropdown">
                        <a className="m-item ml-3 mr-3 m-lg-0" id="submenu1" href="#">Nuestros Seguros <em className="fas fa-chevron-down"></em></a>
                        <ul className="submenu" id="submenu1-list" style={{display:'block'}}>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/alemana-accidentes/plan-full">Seguro Alemana Accidentes</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-total">Seguro Alemana Total</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-65">Seguro Alemana 65+</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/nuestros-seguros/alemana-sur">Seguro Alemana Sur</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="m-item ml-3 mr-3 m-lg-0" href="https://www.alemanaseguros.cl/denuncia-de-siniestros">Denuncia de Siniestros</a>
                    </li>
                    <li className="dropdown">
                        <a className="m-item ml-3 mr-3 m-lg-0" id="submenu2" href="#">Información Corporativa <em className="fas fa-chevron-down"></em></a>
                        <ul className="submenu" id="submenu2-list" style={{display:'none'}} >
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/mision">Misión y Visión</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/directorio">Directorio y Administración</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/antecedentes">Antecedentes de la Empresa</a></li>
                            <li className="ml-4"><a href="https://www.alemanaseguros.cl/redirect/terminos-y-condiciones-del-sitio-web">Términos y Condiciones de Uso de Sitio Web</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="m-item ml-3 mr-3 m-lg-0" href="https://www.alemanaseguros.cl/noticias">Noticias</a>
                    </li>
                    <li>
                        <a className="m-item ml-3 mr-3 m-lg-0" href="https://www.alemanaseguros.cl/contacto">Contacto</a>
                    </li>
                </ul>
                </nav>
            </div>

           
        </div>



    )
}

export default Navbar
