import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


function DenunciaRecepcionada() {
    return (
        <>
            <Navbar></Navbar>
                <div className="ca-complaint-result">
                    <div className="container py-5">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-6">
                                <div className="ca-card text-center p-5">
                                    <h3>Su Denuncia ha sido recepcionada</h3>
										<a href="https://www.alemanaseguros.cl" className="nav-link btn ca-button">Volver a Alemana Seguros</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer></Footer>
        </>
    )
}

export default DenunciaRecepcionada
