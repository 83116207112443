import React from 'react'
import Content from '../components/Content'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Home() {
    return (
        <div className="App">
            <Navbar></Navbar>
            <Content></Content>
            <Footer></Footer>
        </div>
    )
}

export default Home
