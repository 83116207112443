import React from "react";

function Modal() {
 
  return (
    <div>
      <div
        className="modal fade ca-modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <h2>Canal de Denuncia por Ley 20.393</h2>
                <div className="card ca-card">
                  <h3>Denuncias No Anónimas</h3>
                  <li className="btn ca-button">
                    <a href={'/denunciasnoanonimas'} className="nav-link btn ca-button">Denunciar</a>
                  </li>
                </div>
                <div className="card ca-card">
                  <h3>Denuncias Anónimas</h3>
                  <li className="btn ca-button">
                    <a href={'/denunciasanonimas'} className="nav-link btn ca-button">Denunciar</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
