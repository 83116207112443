import React from 'react';
import {BrowserRouter as Router,Redirect, Route, Switch } from 'react-router-dom'; 

// Styles
import './App.scss';

// Pages
import Home from './pages/Home';
import Login from './pages/Login';

import Denuncia from './pages/Denuncia';
import DenunciaList from './pages/DenunciaList';

import DenunciasAnonimas from './pages/DenunciasAnonimas';
import DenunciasNoAnonimas from './pages/DenunciasNoAnonimas';
import DenunciasMarcoEtico from './pages/DenunciasMarcoEtico';
import DenunciaRecepcionada from './pages/DenunciaRecepcionada';

import {ProvideAuth} from './services/AlemanaAuth';
import {PrivateRoute} from './services/PrivateRoute';

function App() {
  return (
<ProvideAuth>
    <Router> 
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />

        <PrivateRoute  path="/denuncias">
          <DenunciaList />
        </PrivateRoute >
        <PrivateRoute  path="/denuncia/:id">
          <Denuncia />
        </PrivateRoute >

        <Route path="/denunciasanonimas" component={DenunciasAnonimas} />
        <Route path="/denunciasnoanonimas" component={DenunciasNoAnonimas} />
        <Route path="/denunciasmarcoetico" component={DenunciasMarcoEtico} />
        <Route path="/denunciarecepcionada" component={DenunciaRecepcionada} />
      </Switch> 
    </Router>
  </ProvideAuth>
  );
}

export default App;
