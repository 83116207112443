import React from 'react'

function Footer() {
    return (
        <div>
        <footer className="d-none d-sm-none d-md-block page-footer font-small blue-grey lighten-5"> 
            <div className="container text-md-left mt-5"> 
                <div className="row mt-3 dark-grey-text"> 
                    <div className="col-sm-4">

                    <h6 className="text-light font-weight-bold">Instituciones Relacionadas</h6>
                    <ul className="list-unstyled text-small">
                        <li className="pb-2"><a href="http://www.alemana.cl/" className="text-light">Clínica Alemana </a></li>
                        <li className="pb-2"><a href="http://www.alemanatemuco.cl/" className="text-light">Clínica Alemana Temuco </a></li>
                        <li className="pb-2"><a href="http://www.alemanavaldivia.cl/" className="text-light">Clínica Alemana Valdivia </a></li>
                        <li className="pb-2"><a href="http://www.ccab.cl/" className="text-light">Corporación Chileno Alemana de Beneficencia </a></li>
                        <li className="pb-2"><a href="http://www.hogaresalemanes.cl/" className="text-light">Hogares Alemanes </a></li>
                        <li className="pb-2"><a href="http://www.hogaresalemanesvaldivia.cl/" className="text-light">Hogares Alemanes de Valdivia </a></li>
                    </ul>

                    </div> 
                    <div className="col-sm-3">
                        <h6 className="text-light font-weight-bold">Corporativo</h6>
                        <ul className="list-unstyled text-small">
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/mision">Misión y Visión</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/directorio">Directorio y Administración</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/antecedentes">Antecedentes de la Empresa</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/estados-financieros">Estados Financieros</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/informacion-legal">Información Legal</a></li>
                            <li className="pb-2"><a className="text-light" href="/">Canal de denuncias</a></li>
                        
                        </ul>

                    </div> 
                    <div className="col-sm-2">
                        <h6 className="text-light  font-weight-bold">Link de Interés</h6>
                        <ul className="list-unstyled text-small">
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/redirect/comision-para-el-mercado-financiero">CMF Chile</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/redirect/informativo-de-atencion-a-reclamos">Informativo C.2131</a></li>
                            <li className="pb-2"><a className="text-light" href="https://www.alemanaseguros.cl/redirect/pago-de-primas">Pago de Primas</a></li>
                            <li className="pb-2"><a className="text-light" href="https://s3alemana.s3.amazonaws.com/docs/librariesprovider7/default-document-library/terminosusoypoliticasitioweb.pdf?sfvrsn=a0e50f5_2">Términos de Uso</a></li>
                        </ul>

                    </div> 
                    <div className="col-sm-3"> 
                        <a className="logo-footer mb-3" href="https://www.alemanaseguros.cl"> <img src="logo.svg" alt="" /> </a>
                        <ul className="list-unstyled text-small mt-3">
                            <li className="pb-2"><a href="maito: seguros@alemanaseguros.cl" className="text-light">seguros@alemanaseguros.cl</a></li>
                            <li className="pb-2"><a href="phone:+56 22910 4900" className="text-light">+56 22910 4900</a></li>
                            <li className="pb-2"><p className="text-light">Lunes a Viernes | 09:00 a 17:00 hrs</p></li>
                        </ul>
                    </div> 
                </div> 
            </div> 
        </footer>
        <footer className="d-block d-sm-block d-md-none page-footer font-small blue-grey lighten-5"> <div className="container"> <div className="row"> <div className="mx-auto"><a className="logo-footer" href="/"> <img src="logo.svg" alt=""/> </a></div></div> </div> </footer>
        </div>
    )
}

export default Footer
