//import React from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import DenunciaService from "../services/DenunciaService";


function Denuncia() {
  const { id } = useParams();
  const [item, setItem] = useState([]);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const denuncia = await DenunciaService.getDetalleDenuncia(id);
    setItem(denuncia);
  };

  const documentURL = DenunciaService.getURLDocumento(id);

  function clickedDownload(){
    var link = document.getElementById("linkDocumento");
    link.click();
  }

  const MetadataDenuncia = prop => {
    const { anonimo,rut,nombres,email } = prop;
     if(anonimo){
      return [<h4>Denuncia Anonima</h4>,<p>&nbsp;</p>];
     }else {
      return [<h4>RUT</h4>,<p>{rut}</p>,<h4>Nombre</h4>,<p>{nombres}</p>,<h4>Email</h4>,<p>{email}</p>];
     }
  };

  const history = useHistory()

  // Download detalle denuncia
  const downloadPdfDocument = (rootElementId) => {
    const input = document.getElementById(rootElementId);
    let divHeight = input.clientHeight;
    let divWidth = input.clientWidth;
    let ratio = divHeight / divWidth;

    html2canvas(input, {
      height: divHeight,
      width: divWidth
    })
      .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 1.0);
          const pdf = new jsPDF();
          var width = pdf.internal.pageSize.getWidth();    
          var height = pdf.internal.pageSize.getHeight();
          height = ratio * width;
          pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
          pdf.save(item.rut+".pdf");
      })
  }

  function downloadDetail(){
    downloadPdfDocument("complaintDetail");
  }

  return (
    <div>
      <Navbar></Navbar>

      <div  className="ca-detail py-5">
        <div className="container" id="complaintDetail">
          <div className="row col-md-10 mx-auto">
            <div className="col-md-6">
              <h2 className="py-5">Detalle denuncia</h2>
            </div>
            <div className="col-md-6">
              <div className="py-5">
                <div className="ca-button ca-button--back ms-auto" onClick={() => history.go(-1)}>Volver</div>
                

              </div>
            </div>
          </div>
          <div className="row col-md-10 mx-auto">
          <div className="table-responsive">
            <table className="table ca-table-detail ">
              <thead>
                <tr>
                  <th scope="col">N° de denuncia</th>
                  <th scope="col">Tipo de denuncia</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Hora</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{item.id}</th>
                  <td>{item.canal}</td>
                  <td>{new Intl.DateTimeFormat('es-CL', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(item.fechaIngreso)}</td>
                  <td>{new Intl.DateTimeFormat('es-CL', { hour: 'numeric', minute: 'numeric',timeZone: 'America/Santiago'}).format(item.fechaIngreso)}</td>
                  <td>
                    <button 
                      onClick={downloadDetail}
                      className="btn ca-button ca-button--secondary"
                    >
                      Descargar Detalle
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row ca-detail--content">
              <div className="col-md-4">
                
              <MetadataDenuncia anonimo={item.anonimo} rut={item.rut} nombres={item.nombres} email={item.email}></MetadataDenuncia>
          
                <h4>Denuncia</h4>
                <p>{item.tipo}</p>
                </div>
              <div className="col-md-8">
                <h4>Descripción</h4>
                <p>{item.descripcion}</p>
            
                <h4>Archivo adjunto</h4>
                <p>{item.nombreArchivo}</p>
                <button 
                  className={`btn ca-button ca-button--file + ${item.nombreArchivo ? '' : 'disabled'}`}
                  onClick={clickedDownload}
                >
                  Descargar Archivo
                </button>
              </div>
              <a href={documentURL} style={{display: 'none'}}  id="linkDocumento"></a>
          </div>
        </div>
          </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Denuncia;